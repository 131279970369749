import React, { Component } from 'react';
import {connect } from 'react-redux';


class Loading extends Component {



  render() {
    return (
        <div className="loading text-center">Loading ...</div>
    );
  }
}

const mapStateToProps = (state) => {
  return { state };
}

const dispatchToProps = {
   
}

export default connect(mapStateToProps, dispatchToProps)(Loading);



