import React, { Component } from "react";
import { connect } from "react-redux";

import FormContact from "./FormContact";
import FormModules from "./FormModules";
import Formproject from "./FormProject";
import MessageThanks from "./MessageThanks";
import Loading from "./Loading";

class Home extends Component {
  render() {
    const { state } = this.props;

    let content = <FormContact />;

    if (state.dataServerId != null && state.contactFormPostSuccess == true) {
      content = <FormModules />;
    }
    if (
      state.dataServerId != null &&
      state.contactFormPostSuccess == true &&
      state.formModulesPostSuccess == true
    ) {
      content = <Formproject />;
    }
    if (
      state.dataServerId != null &&
      state.contactFormPostSuccess == true &&
      state.formModulesPostSuccess == true && 
      state.formProjectPostSuccess == true 
    ) {
      content = <MessageThanks />;
    }
    
    // for debug
    // content = <Formproject />;
    

    if (state.contactFormPostWip == true || state.formModulesPostWip == true || state.formProjectPostWip == true) {
      content = <Loading />;
    }

    return (
      <div>
        <div className="container">
          <div className="col-md-12">{content}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { state };
};

const dispatchToProps = {};

export default connect(mapStateToProps, dispatchToProps)(Home);
