import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Link } from "react-router-dom";

import langUtils from '../langUtils';

class About extends Component {

  componentDidMount() {
    window.scrollTo(0, 0)
  }
  
  render() {

    const { state } = this.props;

    const langTexts = langUtils.get(state.currentLang);

          
    return (
      <div>
        <div className="container">
          <div className="col-md-12">
            <div className="content-boxed-wrapper">
              <h2 className="mb-3">{langTexts.navItemAbout}</h2>

              <p>Les informations recueillies sur ce formulaire sont enregistrées dans un fichier informatisé par SAROS - NETRESTO pour usage commercial.</p>
              
              <p>Les données collectées seront communiquées aux seuls destinataires suivants : NETRESTO - <a href="mailto:contact@netresto.com">contact@netresto.com</a>.</p>

              <p>Les données sont conservées pendant une durée d'un an.</p>

              <p>Vous pouvez accéder aux données vous concernant, les rectifier, demander leur effacement ou exercer votre droit à la limitation du traitement de vos données. </p>

              <p>Consultez le site cnil.fr pour plus d’informations sur vos droits.</p>

              <p>Pour exercer ces droits ou pour toute question sur le traitement de vos données dans ce dispositif, vous pouvez contacter  : <a href="mailto:contact@netresto.com">contact@netresto.com</a>, 6 Rue de la Trémoille 75008 PARIS, <a href="tel:+33153230840">01.53.23.08.40</a></p>

              <p>Si vous estimez, après nous avoir contactés, que vos droits « Informatique et Libertés » ne sont pas respectés, vous pouvez adresser une réclamation à la CNIL.</p>
              
              <p>
                <Link to="/">{langTexts.aboutBack}</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { state };
}

const dispatchToProps = {
}

export default connect(mapStateToProps, dispatchToProps)(About);
