import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Link } from "react-router-dom";

import langUtils from '../langUtils';

class About extends Component {

  componentDidMount() {
    window.scrollTo(0, 0)
  }
  
  render() {

    const { state } = this.props;

    const langTexts = langUtils.get(state.currentLang);

          
    return (
      <div>
        <div className="container">
          <div className="col-md-12">
            <div className="content-boxed-wrapper">
              <h2 className="mb-3">{langTexts.navItemNotFound}</h2>

              <p>{langTexts.notFoundText}</p>
              
              <p>
                <Link to="/">{langTexts.aboutBack}</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { state };
}

const dispatchToProps = {
}

export default connect(mapStateToProps, dispatchToProps)(About);
