import React, { Component } from 'react'
import { Provider } from 'react-redux'
import store from './store'
import RootComponent from './components/RootComponent'

class App extends Component {

  render () {
    return (
      <Provider store={ store }>
        <RootComponent />
      </Provider>
    )
  }
}

export default App
