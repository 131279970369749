const prod = {
    // API_URL: 'https://preprod.netresto.com'
    API_URL: 'https://contact.mycawan.com'
};
const dev = {
    // API_URL: 'http://preprod.netresto.com'        
    // API_URL: "http://10.26.135.108:5000" // alb home
    API_URL: "http://192.168.100.12:5000" // alb saros lan
    // API_URL: "http://192.168.0.121:5000" // alb saros freebox
    // API_URL: "http://192.168.100.29:8080" // denis lan
};

export const config = process.env.NODE_ENV === 'development' ? dev : prod;