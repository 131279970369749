import React, { Component, useRef } from 'react';
import { connect } from 'react-redux';

import { Form, Button, Alert} from 'react-bootstrap';
import { Link } from "react-router-dom";

import langUtils from '../langUtils';

import { 
  onChangeFormField,
  onSubmitModulesForm
} from '../store/actions/actions'


class MessageThanks extends Component {

  constructor(props) {
    super(props)
    this.errorElementRef = React.createRef()  
}

  componentDidMount() {
    const { state } = this.props; 
    window.scrollTo(0, 0);
    if(state.postSuccess != null) {
      if(state.postSuccess == false) {
        this.scrollToError();
      }
    }
  }

  onClickSubmit = (e) => {
    e.preventDefault()
    this.props.onSubmitModulesForm(this.props.state);
  }
  
  onChangeFieldValue = (field, value) => {
    console.log("changing field ", field, value);
    let o = {};
    o[field] = value;
    this.props.onChangeFormField(o);
    
  }

  scrollToError = () => window.scrollTo(0, this.errorElementRef.current.offsetTop) 
  
  render() {

    const { state } = this.props;                                                                         
    const langTexts = langUtils.get(state.currentLang);

    let formOk = true;
    if( state.formProjectFieldNow == false 
      && state.formProjectFieldOuv == false
      && state.formProjectFieldRef == false
      ) {
      formOk = false;
    }

    let postErrorNode = null;
    if(state.contactFormPostSuccess != null) {
      if(state.contactFormPostSuccess == false) {
        
        postErrorNode = (
          <Alert variant="danger" className="text-center" ref={this.errorElementRef}>
            <p>{state.postError}</p>
          </Alert>
        );

      }
    }

    let form = (
      <div>
        <h2>{langTexts.msgThanksTitle}</h2>
        <p>{langTexts.msgThanksText}</p>

        <a href="http://www.mycawan.com">{langTexts.msgThanksLink}</a>


      </div>
    );

    let content = form;

    if(state.postSuccess != null) {
      if(state.postSuccess == true) {
        
        content = (
          <div>
             <Alert variant="success" className="text-center">
              <p>{langTexts.postSuccess}</p>
            </Alert>
          </div>
        )        
      }
    }

    




    return (
      <div>

        {content}

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { state };
}

const dispatchToProps = {
  onChangeFormField,
  onSubmitModulesForm
}

export default connect(mapStateToProps, dispatchToProps)(MessageThanks);
