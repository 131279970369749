import React, { Component } from 'react';
import {connect } from 'react-redux';

import logo from '../images/logo_mycawan_by_netresto_vertical_bleu.png';
import flagFr from '../images/france-flag-xs.png';
import flagUs from '../images/united-states-of-america-flag-xs.png';
import langUtils from '../langUtils';


// import { 
//   onChangeLang
// } from '../store/actions/actions'

class MyNavBar extends Component {

  // onClickFlag = (lang) => {
  //   if(this.props.state.currentLang != lang) {
  //     this.props.onChangeLang(lang);
  //   }
  // }

  render() {

    const { state } = this.props;

    const langTexts = langUtils.get(state.currentLang);


    let styleFlagOn = {  width: '30px', height: '20px' };
    let styleFlagOff = {  width: '30px', height: '20px', opacity: "0.3" };
    let imgFlagFr = (<img src={flagFr} style={state.currentLang == "fr" ? styleFlagOff : styleFlagOn } onClick={() => this.onClickFlag("fr")}/>);
    let imgFlagUs = (<img src={flagUs} style={state.currentLang == "en" ? styleFlagOff : styleFlagOn } onClick={() => this.onClickFlag("en")}/>);

    return (

      <div>
        <header>
          
          <div>
            <img src={logo} className="App-logo" alt="logo" style={{ height: '160px' }} />
            <div className="font-title" style={{fontSize: "2em", marginTop: "1em"}}>{langTexts.headerTitle}</div>
          </div>
          <div className="header-navbar">
            {/* <Link to="/">{langTexts.navItemForm}</Link>
            <Link to="/about">{langTexts.navItemAbout}</Link> */}
            {/* <div style={{marginLeft: 'auto'}}>
              <div >{imgFlagFr}</div>
              <div style={{marginTop: ".5em"}}>{imgFlagUs}</div>
            </div> */}
          </div>
        </header>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { state };
}

const dispatchToProps = {
  // onChangeLang
}

export default connect(mapStateToProps, dispatchToProps)(MyNavBar);



