export const ON_TOUCH_POST_WIP = "ON_TOUCH_POST_WIP";
export const ON_TOUCH_POST_RESULT = "ON_TOUCH_POST_RESULT";

export const ON_CHANGE_LANG = "ON_CHANGE_LANG";
export const ON_CHANGE_FORM_FIELD = "ON_CHANGE_FORM_FIELD";

export const ON_CONTACT_FORM_POST_WIP = "ON_CONTACT_FORM_POST_WIP";
export const ON_CONTACT_FORM_POST_RESULT = "ON_CONTACT_FORM_POST_RESULT";

export const ON_FORM_MODULES_POST_WIP = "ON_FORM_MODULES_POST_WIP";
export const ON_FORM_MODULES_POST_RESULT = "ON_FORM_MODULES_POST_RESULT";

export const ON_FORM_PROJECT_POST_WIP = "ON_FORM_PROJECT_POST_WIP";
export const ON_FORM_PROJECT_POST_RESULT = "ON_FORM_PROJECT_POST_RESULT";




