import React, { Component, useRef } from 'react';
import { connect } from 'react-redux';

import { Form, Button, Alert} from 'react-bootstrap';
import { Link } from "react-router-dom";

import langUtils from '../langUtils';

import { 
  onChangeFormField,
  onSubmitProjectForm
} from '../store/actions/actions'


class FormProject extends Component {

  constructor(props) {
    super(props)
    this.errorElementRef = React.createRef()  
}

  componentDidMount() {
    const { state } = this.props; 
    window.scrollTo(0, 0);
    if(state.postSuccess != null) {
      if(state.postSuccess == false) {
        this.scrollToError();
      }
    }
  }

  onClickSubmit = (e) => {
    e.preventDefault()
    this.props.onSubmitProjectForm(this.props.state);
  }
  
  onChangeFieldValue = (field, value) => {
    console.log("changing field ", field, value);

    let o = {};
    
    if(field != "formProjectComment") {
      // pour eviter plusieurs cases cochees on decoche tout avant de cocher celle choisi par l'utilisateur
      o.formProjectNow = false;
      o.formProjectOuv = false;
      o.formProjectRef = false;
    }
    
    o[field] = value;
    
    
    this.props.onChangeFormField(o);
    
  }

  scrollToError = () => window.scrollTo(0, this.errorElementRef.current.offsetTop) 
  
  render() {

    const { state } = this.props;                                                                         
    const langTexts = langUtils.get(state.currentLang);

    let formOk = true;
    if( state.formProjectNow == false 
      && state.formProjectOuv == false
      && state.formProjectRef == false
      ) {
      formOk = false;
    }

    let postErrorNode = null;
    if(state.contactFormPostSuccess != null) {
      if(state.contactFormPostSuccess == false) {
        
        postErrorNode = (
          <Alert variant="danger" className="text-center" ref={this.errorElementRef}>
            <p>{state.postError}</p>
          </Alert>
        );

      }
    }

    let commentLength = state.formProjectComment != null ? state.formProjectComment.length : 0;

    let form = (
      <div>
        <h2>{langTexts.formProjectTitle}</h2>
        <p>{langTexts.formProjectIntro}</p>

        <Form>

          <Form.Group controlId="formProjectNow">
            <Form.Check type="checkbox" checked={state.formProjectNow} label={langTexts.formProjectFieldNow} onChange={(e) => this.onChangeFieldValue("formProjectNow", e.target.checked)}/>
          </Form.Group>

          <Form.Group controlId="formProjectOuv">
            <Form.Check type="checkbox" checked={state.formProjectOuv} label={langTexts.formProjectFieldOuv} onChange={(e) => this.onChangeFieldValue("formProjectOuv", e.target.checked)}/>
          </Form.Group>

          <Form.Group controlId="formProjectRef">
            <Form.Check type="checkbox" checked={state.formProjectRef} label={langTexts.formProjectFieldRef} onChange={(e) => this.onChangeFieldValue("formProjectRef", e.target.checked)}/>
          </Form.Group>

          <Form.Group controlId="formProjectComment">
          <Form.Control
              as="textarea"
              placeholder={langTexts.formProjectFieldComment}
              style={{ height: '100px' }}
              value={state.formProjectFieldComment}
              onChange={(e) => this.onChangeFieldValue("formProjectComment", e.target.value)}
              />
              {/* <div style={{textAlign: "right", fontSize: "0.8em"}}>{commentLength} /255</div> */}
          </Form.Group>

          {postErrorNode}

          <Button variant={formOk ? "primary" : "secondary"} type="submit" disabled={!formOk} onClick={(e) => this.onClickSubmit(e)}>{langTexts.formBtnSubmit}</Button>
        </Form>


      </div>
    );

    let content = form;

    if(state.postSuccess != null) {
      if(state.postSuccess == true) {
        
        content = (
          <div>
             <Alert variant="success" className="text-center">
              <p>{langTexts.postSuccess}</p>
            </Alert>
          </div>
        )        
      }
    }

    




    return (
      <div>

        {content}

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { state };
}

const dispatchToProps = {
  onChangeFormField,
  onSubmitProjectForm
}

export default connect(mapStateToProps, dispatchToProps)(FormProject);
