import moment from 'moment';
import {
  ON_TOUCH_POST_WIP,
  ON_TOUCH_POST_RESULT,
  ON_CHANGE_LANG,
  ON_CHANGE_FORM_FIELD,
  ON_CONTACT_FORM_POST_WIP,
  ON_CONTACT_FORM_POST_RESULT,
  ON_FORM_MODULES_POST_WIP,
  ON_FORM_MODULES_POST_RESULT,
  ON_FORM_PROJECT_POST_WIP,
  ON_FORM_PROJECT_POST_RESULT
  // ON_RESET_FORM
} from '../actions/constants'

import langUtils from '../../langUtils';

// {idSite: "553", token: "EHJH5EXP5LVL17LAWRB8GFJRX6JIRJ7A", codeValue: "http://192.168.100.20:3001/?&idSite=553&token=EHJH5EXP5LVL17LAWRB8GFJRX6JIRJ7A"}

const initialState = {
  currentLang: 'fr',
  
  //codeValue: "http://192.168.100.20:3001/?&idSite=553&token=2YW9Y6MR8Y6Q6NPF01SQSV3F0L9JZ46G",
  // idSite: 553,
  // token: "2YW9Y6MR8Y6Q6NPF01SQSV3F0L9JZ46G",

  codeValue: null, 
  token: null,

  
  dataServerId: null,

  uuid: null,
  touchPostWip: false,

  contactFormLastname: null,
  contactFormFirstname: null,
  contactFormSociete: null,
  contactFormTel: null,
  contactFormEmail: null,
  contactFormRegion: null,
  contactFormAccept: null,

  contactFormPostWip: false,
  contactFormPostSuccess: null,
  contactFormPostError: null,

  formModulesHaccp: false,
  formModulesRh: false,
  formModulesGestion: false,
  formModulesFull: false,
  formModulesPostWip: false,
  formModulesPostSuccess: null,
  formModulesPostError: null,

  formProjectNow: false,
  formProjectOuv: false,
  formProjectRef: false,
  formProjectComment: null,
  formProjectPostWip: false,
  formProjectPostSuccess: null,
  formProjectPostError: null,

  formRegionList: [
    {code: "o", label: ""},
    {code: "pa", label: "IDF"},
    {code: "se", label: "SUD-Est"},
    {code: "so", label: "SUD-Ouest"},
    {code: "ne", label: "NORD-Est"},
    {code: "no", label: "NORD-Ouest"},
    {code: "dt", label: "DOM-TOM"},
    {code: "et", label: "ETRANGER"},
  ]
}

export default (state = initialState, action) => {

  console.log("current state", state);

  const langTexts = langUtils.get(state.currentLang);

  switch (action.type) {

  
    
      /////
    case ON_CHANGE_LANG:
      {
        console.log("reducer - ", ON_CHANGE_LANG, action);
      
        const lang = action.payload;

        return {
          ...state,
          currentLang: lang
        }
      }

      case ON_TOUCH_POST_WIP: {
        console.log("reducer - ", ON_TOUCH_POST_WIP, action);
        return {
          ...state,
          uuid: action.payload,
          touchPostWip: true
        }
      }

      case ON_TOUCH_POST_RESULT: {
        console.log("reducer - ", ON_TOUCH_POST_RESULT, action);
        return {
          ...state,
          touchPostWip: false
         
        }
      }

      case ON_CHANGE_FORM_FIELD:
      {
        console.log("reducer - ", ON_CHANGE_FORM_FIELD, action);
      
        const values = action.payload;

        return {
          ...state,
          ...values
        }
      }

      case ON_CONTACT_FORM_POST_WIP:
        {
          console.log("reducer - ", ON_CONTACT_FORM_POST_WIP, action);
        
          return {
            ...state,
            contactFormPostWip: true,
            contactFormPostSuccess: null,
            contactFormPostError: null
          }  
  
        }

      case ON_CONTACT_FORM_POST_RESULT:
      {
        console.log("reducer - ", ON_CONTACT_FORM_POST_RESULT, action);
      
        const payload = action.payload;
  
        if(payload != null && payload.success == true) {
          return {
            ...state,
            dataServerId: payload.json.insertId,
            contactFormPostWip: false,
            contactFormPostSuccess: true,
            contactFormPostError: null
          }
        } else {
          let errorMessage = langTexts.postError;
          errorMessage += payload.error;
                    
          return {
            ...state,
            contactFormPostWip: false, 
            contactFormPostSuccess: false ,
            contactFormPostError: errorMessage
          }
        }
        
      }

      case ON_FORM_MODULES_POST_WIP:
        {
          console.log("reducer - ", ON_CONTACT_FORM_POST_WIP, action);
        
          return {
            ...state,
            formModulesPostWip: true,
            formModulesPostSuccess: null,
            formModulesPostError: null
          }  
  
        }

      case ON_FORM_MODULES_POST_RESULT:
      {
        console.log("reducer - ", ON_FORM_MODULES_POST_RESULT, action);
      
        const payload = action.payload;
  
        if(payload != null && payload.success == true) {
          return {
            ...state,
            formModulesPostWip: false,
            formModulesPostSuccess: true,
            formModulesPostError: null
          }
        } else {
          let errorMessage = langTexts.postError;
          errorMessage += payload.error;
                    
          return {
            ...state,
            formModulesPostWip: false, 
            formModulesPostSuccess: false ,
            formModulesPostError: errorMessage
          }
        }
        
      }


      case ON_FORM_PROJECT_POST_WIP:
        {
          console.log("reducer - ", ON_FORM_PROJECT_POST_WIP, action);
        
          return {
            ...state,
            formProjectPostWip: true,
            formProjectPostSuccess: null,
            formProjectPostError: null
          }  
  
        }

      case ON_FORM_PROJECT_POST_RESULT:
      {
        console.log("reducer - ", ON_FORM_PROJECT_POST_RESULT, action);
      
        const payload = action.payload;
  
        if(payload != null && payload.success == true) {
          return {
            ...state,
            formProjectPostWip: false,
            formProjectPostSuccess: true,
            formProjectPostError: null
          }
        } else {
          let errorMessage = langTexts.postError;
          errorMessage += payload.error;
                    
          return {
            ...state,
            formProjectPostWip: false, 
            formProjectPostSuccess: false ,
            formProjectPostError: errorMessage
          }
        }
        
      }


    default:
      console.log("reducer - default");
      return state
  }
};
