import { 
  ON_TOUCH_POST_WIP,
  ON_TOUCH_POST_RESULT,
  ON_CHANGE_LANG,
  ON_CHANGE_FORM_FIELD,
  ON_CONTACT_FORM_POST_WIP,
  ON_CONTACT_FORM_POST_RESULT,
  ON_FORM_MODULES_POST_WIP,
  ON_FORM_MODULES_POST_RESULT,
  ON_FORM_PROJECT_POST_WIP,
  ON_FORM_PROJECT_POST_RESULT
} from './constants';

import { config } from '../../config'

// export const onUrlTouch = (source) => {
//   return (dispatch) =>  {

   
//     let URL = config.API_URL + "/api/urltouch" + "?source=" + source;
//     console.log("get request to " + URL);

//     fetch(URL, {
//       method: 'get'
//     })
//   }
// }

export const onTouch = (uuid, source, action) => {
  // send initial post request to add new entry on DB
  // with data :
  // 
  // source : identifier got from URL provided via QR codes
  // uuid   : generated uuid to identify website visitor
  // 

  return (dispatch) =>  {

    dispatch({
      'type': ON_TOUCH_POST_WIP,
      'payload': uuid
    });

    let postData = {
      source,
      action,
      uuid
    }

    let URL = config.API_URL + "/api/touch"
    console.log("post request to " + URL);

    fetch(URL, {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(postData)
    }).then(res=>res.json())
      .then(data => {
        dispatch({
          'type': ON_TOUCH_POST_RESULT,
          'payload': data
        });
      })
      .catch(error => {
        dispatch({
          'type': ON_TOUCH_POST_RESULT,
          'payload': {success: false, error}
        });
      });
  }
}

export const onChangeFormField = (value) => {
  return (dispatch) =>  {
      dispatch({
        'type': ON_CHANGE_FORM_FIELD,
        'payload': value
      });
  }
}


export const onSubmitContactForm = (state) => {
  return (dispatch) =>  {

    dispatch({
      'type': ON_CONTACT_FORM_POST_WIP,
      'payload': null
    });

    let postData = {
      contactFormLastname: state.contactFormLastname,
      contactFormFirstname: state.contactFormFirstname,
      contactFormSociete: state.contactFormSociete,
      contactFormTel: state.contactFormTel,
      contactFormEmail: state.contactFormEmail,
      contactFormRegion: state.contactFormRegion,
      token: state.token,
      uuid: state.uuid
    }

    let URL = config.API_URL + "/api/contact"
    console.log("post request to " + URL);

    fetch(URL, {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(postData)
    }).then(res=>res.json())
      .then(data => {
        dispatch({
          'type': ON_CONTACT_FORM_POST_RESULT,
          'payload': data
        });
      })
      .catch(error => {
        dispatch({
          'type': ON_CONTACT_FORM_POST_RESULT,
          'payload': {success: false, error}
        });
      });
  }
}

export const onSubmitModulesForm = (state) => {
  return (dispatch) =>  {

    dispatch({
      'type': ON_FORM_MODULES_POST_WIP,
      'payload': null
    });

    let postData = {
      id: state.dataServerId,
      // id: 1,
      haccp: state.formModulesHaccp,
      rh: state.formModulesRh,
      gestion: state.formModulesGestion,
      full: state.formModulesFull,
      token: state.token,
      uuid: state.uuid
    }

    let URL = config.API_URL + "/api/modules"
    console.log("post request to " + URL);

    fetch(URL, {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(postData)
    }).then(res=>res.json())
      .then(data => {
        dispatch({
          'type': ON_FORM_MODULES_POST_RESULT,
          'payload': data
        });
      })
      .catch(error => {
        dispatch({
          'type': ON_FORM_MODULES_POST_RESULT,
          'payload': {success: false, error}
        });
      });
  }
}

export const onSubmitProjectForm = (state) => {
  return (dispatch) =>  {

    dispatch({
      'type': ON_FORM_PROJECT_POST_WIP,
      'payload': null
    });

    let postData = {
      id: state.dataServerId,
      // id: 1,
      projectNow: state.formProjectNow,
      projectOuv: state.formProjectOuv,
      projectRef: state.formProjectRef,
      projectComment: state.formProjectComment,
      token: state.token,
      uuid: state.uuid
    }

    let URL = config.API_URL + "/api/project"
    console.log("post request to " + URL);

    fetch(URL, {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(postData)
    }).then(res=>res.json())
      .then(data => {
        dispatch({
          'type': ON_FORM_PROJECT_POST_RESULT,
          'payload': data
        });
      })
      .catch(error => {
        dispatch({
          'type': ON_FORM_PROJECT_POST_RESULT,
          'payload': {success: false, error}
        });
      });
  }
}