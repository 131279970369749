import React, { Component, useRef } from 'react';
import { connect } from 'react-redux';

import { Form, Button, Alert} from 'react-bootstrap';
import { Link } from "react-router-dom";

import langUtils from '../langUtils';

import validator from 'validator';

import { 
  onChangeFormField,
  onSubmitContactForm
} from '../store/actions/actions'


class FormContact extends Component {

  constructor(props) {
    super(props)
    this.errorElementRef = React.createRef()  
}

  // componentDidMount() {
  //   const { state } = this.props; 
  //   window.scrollTo(0, 0);
  //   if(state.postSuccess != null) {
  //     if(state.postSuccess == false) {
  //       this.scrollToError();
  //     }
  //   }
  // }

  onClickSubmit = (e) => {
    e.preventDefault()
    this.props.onSubmitContactForm(this.props.state);
  }
  
  onChangeFiedlValue = (field, value) => {
    
    
    let o = {};

    if(field == "contactFormEmail") {  
      if(!validator.isEmail(value)) {
        console.log("email error : ", value);
        o.formContactEmailError = true;
      } else {
        console.log("email ok : ", value);
        o.formContactEmailError = false;
      }
    }

    o[field] = value;
    this.props.onChangeFormField(o);  
  
  }

  scrollToError = () => window.scrollTo(0, this.errorElementRef.current.offsetTop) 
  
  render() {

    const { state } = this.props;                                                                         
    const langTexts = langUtils.get(state.currentLang);

    let formOk = true;
    if(
         state.contactFormFirstname == null 
      || state.contactFormLastname == null 
      || state.contactFormSociete == null 
      || state.contactFormTel == null 
      || state.contactFormEmail == null 
      || (state.contactFormRegion == null || state.contactFormRegion == "")
      || state.contactFormAccept != true
      || state.formContactEmailError) {
      formOk = false;
    }

    let postErrorNode = null;
    if(state.contactFormPostSuccess != null && state.contactFormPostSuccess == false) {
      postErrorNode = (
        <Alert variant="danger" className="text-center" ref={this.errorElementRef}>
          <p>{state.postError}</p>
        </Alert>
      );    
    }

    let form = (
      <div>
        <p>{langTexts.contactFormIntroText}</p>

        <Form>

        <h2  style={{marginBottom: "1em", borderBottom: "1px solid #DDD"}}>Vous</h2>
          <Form.Group controlId="formLastname">
            <Form.Label>{langTexts.formFieldLastname} (*)</Form.Label>
            <Form.Control type="text" name="lastname" placeholder="" {...{value: state.contactFormLastname}} onChange={(e) => this.onChangeFiedlValue("contactFormLastname", e.target.value)}/>
          </Form.Group>

          <Form.Group controlId="formFirstname">
            <Form.Label>{langTexts.formFieldFirstname} (*)</Form.Label>
            <Form.Control name="firstname" type="text" placeholder="" {...{value: state.contactFormFirstname}} onChange={(e) => this.onChangeFiedlValue("contactFormFirstname", e.target.value)}/>
          </Form.Group>

          <Form.Group controlId="formPhone">
            <Form.Label>{langTexts.formFieldPhone} (*)</Form.Label>
            <Form.Control
              name="tel"
              type="text"
              placeholder="" {...{value: state.contactFormTel}}
              onChange={(e) => this.onChangeFiedlValue("contactFormTel", e.target.value)}
              />
          </Form.Group>

          <Form.Group controlId="formEmail">
            <Form.Label>{langTexts.formFieldEmail} (*)</Form.Label>
            <Form.Control
              name="email"
              type="text"
              placeholder=""
              {...{value: state.contactFormEmail}}
              onChange={(e) => this.onChangeFiedlValue("contactFormEmail", e.target.value)}
              isInvalid={state.formContactEmailError ? true : false }
              />
            <Form.Control.Feedback type="invalid">
              Email non valide
          </Form.Control.Feedback>
          </Form.Group>


          <h2  style={{marginTop: "2em", marginBottom: "1em", borderBottom: "1px solid #DDD"}}>Votre entreprise</h2>

          <Form.Group controlId="formSociete">
            <Form.Label>{langTexts.formFieldSociete} (*)</Form.Label>
            <Form.Control name="societe" type="text" placeholder="" {...{value: state.contactFormSociete}} onChange={(e) => this.onChangeFiedlValue("contactFormSociete", e.target.value)}/>
          </Form.Group>

          <Form.Group controlId="formRegion">
            <Form.Label>{langTexts.formFieldRegion} (*)</Form.Label>
            <Form.Control 
            
            as="select"
            custom
            name="region" {...{value: state.contactFormRegion}} onChange={(e) => this.onChangeFiedlValue("contactFormRegion", e.target.value)}>
              {
                state.formRegionList.map(elem => {
                  return (<option key={elem.code} value={elem.code}>{elem.label}</option>);
                })
              }
            </Form.Control>
          </Form.Group>

          
          <Form.Group controlId="formAccept" style={{marginTop: "4em"}}>
            <Form.Check type="checkbox" label={langTexts.formFieldApproval} checked={state.contactFormAccept ? true: false} onChange={(e) => this.onChangeFiedlValue("contactFormAccept", e.target.checked)}/>
            <Form.Text className="text-muted">
            <Link to="about">{langTexts.formFieldApprovalLink}</Link>
            </Form.Text>
          </Form.Group>
          
          {postErrorNode}

          <Button variant={formOk ? "primary" : "light"} className={formOk ? "btn-enabled" : "btn-disabled"} type="submit" disabled={!formOk} onClick={(e) => this.onClickSubmit(e)}>{langTexts.formBtnSubmit}</Button>
        </Form>

        <p className="mt-2">(*) {langTexts.formMandatoryField}</p>

      </div>
    );

    let content = form;

    if(state.contactFormPostSuccess != null) {
      if(state.contactFormPostSuccess == true) {
        
        content = (
          <div>
             <Alert variant="success" className="text-center">
              <p>{langTexts.postSuccess}</p>
            </Alert>
          </div>
        )        
      }
    }

    




    return (
      <div>

        {content}

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { state };
}

const dispatchToProps = {
  onChangeFormField,
  onSubmitContactForm
}

export default connect(mapStateToProps, dispatchToProps)(FormContact);
