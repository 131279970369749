import React, { Component } from 'react';
import {connect } from 'react-redux';

import { Container, Row, Col } from 'react-bootstrap';
import { Route, BrowserRouter, Switch } from 'react-router-dom'

import moment from 'moment';
import uuid from 'react-uuid'

import MyNavBar from "./MyNavBar"
import Home from "./Home"
import About from "./About"
import Loading from './Loading';
import NotFound from './NotFound';

import { 
  onTouch
} from '../store/actions/actions'

import { config } from '../config'


class RootComponent extends Component {


  constructor(props) {
    super(props);
  }



  render() {  

    console.log("config", config);

    const { state } = this.props;

    console.log("current state root comp : ", state);

    let content = null;


    if(state.touchPostWip) {
      content = <Loading />;
    }

    if(content == null && state.uuid == null && !state.touchPostWip) {
    
      // tracking for URL source
      const queryParams = new URLSearchParams(window.location.search);
      const urlParamSource = queryParams.get('source');
      const urlparamAction = queryParams.get('action');
     
      console.log("source: ", urlParamSource);
      console.log("action value", urlparamAction);

      let myUuid = uuid();
      this.props.onTouch(myUuid, urlParamSource, urlparamAction);
      content = <Loading />;
      
    }

    if(content == null) {
      content = (
        <Container>
        <Row className="row justify-content-sm-center">
          <Col sm={6}>
            <div className="pt-4 pb-4">
              <Switch>
                <Route exact path="/" component={Home} />
                <Route path="/about" component={About} />
                <Route component={NotFound} />
              </Switch>
            </div>
          </Col>
        </Row>
      </Container>
      )
    }


    return (
      <BrowserRouter>
        <div className="App">

          <MyNavBar />

          <div className="page-content">
            {content}
          </div>

          <footer>

            <Container>
              <Row>
                <Col sm={12}>
                  <div className="mt-2">
                    Netresto change de nom et devient Mycawan !
                  </div>
                  <div className="mt-2 mb-1">
                    <a href="http://www.mycawan.com">www.mycawan.com</a>
                  </div>
                  <div>
                    <a href="mailto:contact@mycawan.com">contact@mycawan.com</a><br />
                    <a href="tel:+33153230840">01 53 23 08 40</a>
                  </div>
                 
                </Col>
              </Row>
            </Container>
          </footer>
        </div>
      </BrowserRouter>
      
    );
  }
}

const mapStateToProps = (state) => {
  return { state };
}

const dispatchToProps = {
  onTouch
}

export default connect(mapStateToProps, dispatchToProps)(RootComponent);


