import fr from "./lang/fr.json";
import en from "./lang/en.json";

const langUtils = {

    get: lang => {
        
        let result = {};

        result = {
            ...fr
        };

        if(lang == "en") {
            result = {
                ...result,
                ...en
            }
        }

        return result;
    }

};
export default langUtils;