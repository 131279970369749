import React, { Component, useRef } from 'react';
import { connect } from 'react-redux';

import { Form, Button, Alert} from 'react-bootstrap';
import { Link } from "react-router-dom";

import langUtils from '../langUtils';

import { 
  onChangeFormField,
  onSubmitModulesForm
} from '../store/actions/actions'


class FormModules extends Component {

  constructor(props) {
    super(props)
    this.errorElementRef = React.createRef()  
}

  componentDidMount() {
    const { state } = this.props; 
    window.scrollTo(0, 0);
    if(state.postSuccess != null) {
      if(state.postSuccess == false) {
        this.scrollToError();
      }
    }
  }

  onClickSubmit = (e) => {
    e.preventDefault()
    this.props.onSubmitModulesForm(this.props.state);
  }
  
  onChangeFieldValue = (field, value) => {
    console.log("changing field ", field, value);
    let o = {};
    o[field] = value;
    if(field == "formModulesFull" && value == true) {
      console.log("checking full");
      o["formModulesHaccp"] = false;
      o["formModulesRh"] = false;
      o["formModulesGestion"] = false;
    } else if(field != "formModulesFull" && value == true) {
      console.log("unchecking full");
      o["formModulesFull"] = false;
    }
    this.props.onChangeFormField(o);
    
  }

  scrollToError = () => window.scrollTo(0, this.errorElementRef.current.offsetTop) 
  
  render() {

    const { state } = this.props;                                                                         
    const langTexts = langUtils.get(state.currentLang);

    let formOk = true;
    if( state.formModulesFull == false 
      && state.formModulesHaccp == false
      && state.formModulesRh == false
      && state.formModulesGestion == false) {
      formOk = false;
    }

    let postErrorNode = null;
    if(state.contactFormPostSuccess != null) {
      if(state.contactFormPostSuccess == false) {
        
        postErrorNode = (
          <Alert variant="danger" className="text-center" ref={this.errorElementRef}>
            <p>{state.postError}</p>
          </Alert>
        );

      }
    }

    let form = (
      <div>
        <h2>{langTexts.formModuleTitle}</h2>
        <p>{langTexts.formModuleIntro}</p>

        <Form>

          <Form.Group controlId="formModuleHaccp">
            <Form.Check type="checkbox" checked={state.formModulesHaccp} label={langTexts.formModuleFieldHaccp} onChange={(e) => this.onChangeFieldValue("formModulesHaccp", e.target.checked)}/>
          </Form.Group>

          <Form.Group controlId="formModuleRh">
            <Form.Check type="checkbox" checked={state.formModulesRh} label={langTexts.formModuleFieldRh} onChange={(e) => this.onChangeFieldValue("formModulesRh", e.target.checked)}/>
          </Form.Group>

          <Form.Group controlId="formModuleGestion">
            <Form.Check type="checkbox" checked={state.formModulesGestion} label={langTexts.formModuleFieldGestion} onChange={(e) => this.onChangeFieldValue("formModulesGestion", e.target.checked)}/>
          </Form.Group>

          <Form.Group controlId="formModuleFull">
            <Form.Check type="checkbox" checked={state.formModulesFull} label={langTexts.formModuleFieldFull} onChange={(e) => this.onChangeFieldValue("formModulesFull", e.target.checked)}/>
          </Form.Group>

          {postErrorNode}

          <Button variant={formOk ? "primary" : "secondary"} type="submit" disabled={!formOk} onClick={(e) => this.onClickSubmit(e)}>{langTexts.formBtnNext}</Button>
        </Form>


      </div>
    );

    let content = form;

    if(state.postSuccess != null) {
      if(state.postSuccess == true) {
        
        content = (
          <div>
             <Alert variant="success" className="text-center">
              <p>{langTexts.postSuccess}</p>
            </Alert>
          </div>
        )        
      }
    }

    




    return (
      <div>

        {content}

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { state };
}

const dispatchToProps = {
  onChangeFormField,
  onSubmitModulesForm
}

export default connect(mapStateToProps, dispatchToProps)(FormModules);
